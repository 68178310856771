.c-main-banner {
  background:url('../../images/banner-bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height:992px;
  padding: 0 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.c-main-banner-inner .row {
  align-items: center;
}

.c-main-banner-inner h1 {
  font-size: 54px;
  color: rgb(202, 243, 255);
  font-weight: bold;
  line-height: 1.2;
  text-align: left;
  text-shadow: 0px 3px 5px rgba(0, 0, 0, 0.8);
  margin-bottom: 75px;
}

.c-main-banner-inner h1 span {
  color: #fff;
}

.c-btn-group {
  max-width: 504px;
  display: flex;
  gap: 18px;
}

.c-btn-group {}

.c-quote-btn,
.c-request-btn {
  padding: 11px 20px;
  font-size: 22px;
  font-weight: 700;
  color: #fff;
  border: 2px solid #5de3f4;
  display: inline-block;
  text-align: center;
  min-width: 240px;
  border-radius: 30px;
  line-height: normal;
  box-shadow: 3px 0 10px #03315f;
  color: #F5F5F5;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7); /* Dark shadow to enhance contrast */
}

.c-request-btn {
  border-color: #ff8f1e;
  background-color: #ff8f1e;
}

.c-request-btn:hover {
  border-color: #5de3f4;
  background-color: transparent;
  color: #fff;
}

.c-quote-btn {
  background-color: transparent;
}

.c-quote-btn:hover {
  border-color: #ff8f1e;
  background-color: #ff8f1e;
  color: #fff;
}

@media (max-width:1540px) {
  .c-main-banner-inner h1 {
      font-size: 50px;
  }
}

@media (max-width:1399px) {
  .c-main-banner-inner h1 {
      font-size: 42px;
  }
}

@media (max-width:1199px) {
  .c-main-banner-inner h1 {
      font-size: 32px;
  }

  .c-quote-btn,
  .c-request-btn {
      padding: 11px 20px;
      font-size: 17px;
      min-width: 190px;
  }
}

@media (max-width:991px) {
  .c-main-banner {
      min-height: 1px;
      padding: 160px 0 40px;
  }
}

@media (max-width:767px) {}

@media (max-width:575px) {}

@media (max-width:479px) {
  .c-btn-group {
      flex-wrap: wrap;
  }
}

/* work css */

.c-work-sec {
  padding: 100px 0;
}

.c-work-sec h2 {
  font-size: 48px;
  font-weight: 700;
  color: #000;
  line-height: 1.2;
  margin: 0 0 70px;
  text-align: center;
}

.c-work-sec h2 span {
  color: #0250bd;

}

.c-work-item-in {
  max-width: 305px;
}

.c-work-item .c-work-item-in {
  margin: 0 auto;
  text-align: center;
}

.c-work-item:first-child .c-work-item-in {
  margin-right: auto;
}

.c-work-item:last-child .c-work-item-in {
  margin-left: auto;
}

.c-work-icon {
  width: 180px;
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 10px solid #0250bd;
  margin: 0 auto 35px;
  background-color: #e5eefd;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
}

.c-work-item-in:hover .c-work-icon {
  box-shadow: 0px 20px 60px 0px rgba(3, 80, 189, 0.25);
  transition: all 0.3s ease-in-out;
}

.c-work-item-in h3 {
  font-size: 26px;
  font-weight: 700;
  color: #0250bd;
  line-height: 1.2;
  margin: 0 0 15px;
}

.c-work-item-in p {
  font-size: 20px;
  font-weight: 400;
  color: #3f3f3f;
  line-height: 1.5;
  margin: 0;
}

.c-work-item-in p a {
  color: #ff8f1e;
  font-weight: bold;
}

.c-work-item-in p a:hover {
  border-bottom: 1px solid #ff8f1e;
}

.c-work-sec-shape {
  position: absolute;
  width: 73%;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 65px;
}

.c-work-sec-list-row {
  position: relative;
  z-index: 2;
}

@media (max-width:1399px) {
  .c-work-sec h2 {
      font-size: 38px;
      margin: 0 0 60px;
  }
}

@media (max-width:1280px) {
  .c-work-icon {
      width: 130px;
      height: 130px;
      padding: 27px;
  }

  .c-work-sec-shape {
      top: 50px;
  }

  .c-work-item-in h3 {
      font-size: 20px;
  }

  .c-work-item-in p {
      font-size: 16px;
  }

  .c-work-sec h2 {
      font-size: 32px;
  }
}

@media (max-width:991px) {
  .c-work-sec-list .row [class*="col"] {
      --bs-gutter-y: 40px;
  }

  .c-work-sec-shape {
      display: none;
  }

  .c-work-item-in {
      max-width: 265px;
  }
}

@media (max-width:767px) {
  .c-work-icon {
      margin: 0 auto 20px;
  }

  .c-work-sec h2 {
      font-size: 26px;
      margin: 0 0 0px;
  }

  .c-work-sec {
      padding: 50px 0;
  }
}

/* why choose us css start */

.c-why-choose-us-sec {
  padding: 100px 0 175px;
  text-align: justify;
}

.why-choose-us-inner {
  max-width: 1364px;
  margin: 0 auto;
}

.section-heading {
  max-width: 990px;
  margin: 0 auto 55px;
  text-align: center;
}

.section-heading h2 {
  font-size: 48px;
  font-weight: 700;
  color: #000;
  line-height: 1;
  margin: 0 0 30px;
}

.section-heading p {
  font-size: 20px;
  font-weight: 400;
  color: #404040;
  line-height: 1.5;
  margin: 0;
}

.c-why-choose-us-item-in {
  padding: 53px 48px 74px;
  border-radius: 10px;
  background-color: #edf2fa;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.c-why-icon {
  width: 84px;
}

.c-why-content {
  width: calc(100% - 84px);
  padding-left: 33px;
}

.c-why-content h3 {
  font-size: 26px;
  color: rgb(2, 80, 189);
  font-weight: bold;
  line-height: 1.2;
  margin: 0 0 25px;
}

.c-why-content p {
  font-size: 20px;
  font-weight: 400;
  color: #404040;
  line-height: 1.5;
  margin: 0;
}

.why-choose-us-inner .row [class*="col-"]:nth-child(2) .c-why-choose-us-item-in,
.why-choose-us-inner .row [class*="col-"]:nth-child(3) .c-why-choose-us-item-in {
  background-color: #e7fbfd;
}

.why-choose-us-inner .row [class*="col-"]:hover .c-why-choose-us-item-in {
  box-shadow: 0px 28px 47px 0px #e5edfa;
}

.why-choose-us-inner .row {
  --bs-gutter-y: 40px;
  --bs-gutter-x: 40px;
}

@media (max-width:1399px) {
  .section-heading h2 {
      font-size: 38px;
  }

  .section-heading p {
      font-size: 18px;
  }

  .c-why-content h3 {
      font-size: 20px;
      margin: 0 0 15px;
  }

  .c-why-content p {
      font-size: 16px;
      margin: 0;
  }

  .c-why-icon {
      width: 64px;
  }

  .c-why-content {
      width: calc(100% - 64px);
      padding-left: 26px;
  }
}

@media (max-width:1199px) {
  .section-heading h2 {
      font-size: 32px;
  }

  .section-heading p {
      font-size: 16px;
  }
}

@media (max-width:991px) {
  .c-main-banner-inner h1{
    text-align: center;
  }
  .c-btn-group{
    max-width: 100%;
    justify-content: center;
  }
  .offcanvas.offcanvas-end{
    width:100% !important;
  }
  .offcanvas-body.c-nav-wpr-body{
    background-color:#0351bd !important;
  }
}
@media (max-width:767px) {

  .section-heading h2 {
      font-size: 26px;
      margin-bottom: 20px;
  }

  .section-heading {
      margin: 0 auto 35px;
  }

  .c-why-choose-us-item-in {
      padding: 33px 28px 44px;
  }

  .why-choose-us-inner .row {
      --bs-gutter-y: 20px;
      --bs-gutter-x: 12px;
  }

  .c-why-choose-us-sec {
      padding: 60px 0 65px;
  }

  .c-why-content h3 {
      font-size: 18px;
      margin: 0 0 15px;
  }
  .c-banner-text,
  .c-main-banner-inner h1{
    text-align: center;
  }
  .c-primary-page-sec{
    text-align:center;
  }
}

@media (min-width: 992px) {
  .aos-zoom-in-lg {
    animation-name: zoom-in;
  }
}

@media (max-width: 991.5px) {
  .row-aos-zoom-in-sm {
    animation-name: zoom-in;
  }
}
