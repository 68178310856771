.job-completion {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
  box-sizing: border-box;
  background-color: #f5f5f5;
}

.job-completion .container {
  text-align: center;
  width: 90%;   width: 400px; max-width: 800px;   margin: auto;
}

.job-completion .job-details {
  text-align: left;   display: flex;
  flex-direction: column;
  align-items: center;   margin-top: 40px;
  width: 100%;
  margin-bottom: 40px;
}

.job-completion .job-completion-btn {
  margin-top: 30px;
  margin-bottom: 15px;
}

.job-completion .loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative;
  top: -150px;
  left: 0;
  right: 0;
  bottom: 0;
}

.job-completion .load-cont {
  text-align: center;
  margin-top: 20px;
}

.job-completion .input-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 50px;
}

.job-completion .input-group label {
  display: block;
  margin-bottom: 20px;
  font-weight: bold;
  color: #333;
  width: 100%;
  text-align: center;
}

.job-completion .radio-group {
  display: flex;
  justify-content: center;
  gap: 5px; /* Space between radio buttons */
}

.job-completion .radio-group label {
  margin: 0;
  font-weight: normal;
  font-size: 0.95rem;
}

.job-completion .radio-group input[type="radio"] {
  margin-right: 25px; /* Space between radio button and its label */
}

.job-completion .input-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f9f9f9;
}

.job-completion .input-group .disabled-input {
  color: #999;
  cursor: not-allowed;
}

.job-completion .large-textarea {
  width: 100%;
  height: 150px;
}

.job-completion .center-button {
  text-align: center;
}
