.about-cont {
  text-align: justify;
}

.about-us-image {
  width: 100%;
  height: 93%;
  object-fit: cover;
  border-radius: 5px;
}
