:root {
  --theme-color: #282828;
  --theme-color-grey: #bbc6bf;
  --theme-color-white: #fff;
}

body,
html {
  width: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  font-weight: normal;
  font-style: normal;
  color: #000;
  font-size: 18px;
  line-height: 1.5;
  background-color: #fff;
}

::-moz-selection {
  background-color: #ffffff;
  color: #282828;
  text-shadow: none;
}

::selection {
  background-color: #ffffff;
  color: #282828;
  text-shadow: none;
}

b,
strong {
  font-weight: 700;
}

p {
  margin: 0 0 22px 0;
}

p:last-child {
  margin-bottom: 0;
}

a {
  margin: 0;
  padding: 0;
  text-decoration: none !important;
  outline: none !important;
  color: var(--theme-color-white);
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

a:hover {
  color: var(--theme-color-red);
}

img {
  max-width: 100%;
}

/* Remove spin buttons in all number fields */
/* For Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
/* For Chrome, Safari, Edge */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.small-input {
  width: 30px;
  font-size: 16px;
  padding: 4px;
  text-align: center;
}

.App {
  display: flex;
  flex-direction: column;
}

.ReactModal__Content {
  width: 42%;
  height: 250px;
  text-align: center;
  margin: auto;
  /*Hide scrollbar */
  scrollbar-width: none; /* For Firefox and IE 11 */
  -ms-overflow-style: none; /* For Edge */
}

/*Hide scrollbar Chrome */
.ReactModal__Content::-webkit-scrollbar {
    display: none; /* For Chrome, Safari and Opera */
}

.ReactModal__Content h2 {
  margin-top: 2%;
}

@media (max-width: 1000px) {
  .ReactModal__Content {
    width: 60%;
    height: 250px;
  }
}

@media (max-width: 820px) {
  .ReactModal__Content {
    width: 70%;
    height: 250px;
  }
}

.page-enter {
  opacity: 0;
  transform: translateY(1%);
}

.page-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 600ms, transform 300ms;
}

.main-app-loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}


.o-footer{
  background:#0049be url('../src/images/footer-bg.jpg') no-repeat right top;
  padding:75px 0;
}

.c-site-info h4{
  color:#d8e6fd;
  font-size:24px;
  font-weight:600;
  display:flex;
  align-items:center;
  margin:0 0 14px;
}

.c-site-info h4 img{
  margin-right:8px;
}

.c-site-info a{
  color:#d8e6fd;
  font-size:20px;
  font-weight:500;
}

.c-site-info ul{
  padding:0;
  margin:0;
  display:flex;
  align-items:center;
  list-style:none;
}

.c-site-info ul li + li{
  margin-left:24px;
}

.o-footer-nav-area{
  border-top:#4668df 1px solid;
  margin:34px 0 0 0;
  padding:34px 0 0 0;
}

.c-nav-sub ul{
  padding:0;
  margin:0;
  list-style:none;
}

.c-nav-sub ul > li{
  display:block;
  line-height:1em;
  margin:0 0 15px;
}

.c-nav-sub ul > li > a{
  color:#d8e6fd;
  font-size:16px;
  font-weight:400;
  text-transform:uppercase;
}

.o-footer-nav-area p{
  color:#d8e6fd;
  font-size:16px;
  font-weight:400;
  margin:0;
}

/* faq css start */

.c-faq-sec {
  background: #f6f6f6;
  padding: 110px 0 200px;
  position: relative;
  background-size: cover;
  background-repeat: repeat;
  background-position: center;
  background-blend-mode: lighten;
  text-align: justify;
}

.c-faq-sec h2 {
  font-size: 48px;
  font-weight: 700;
  color: #000;
  line-height: 1.2;
  margin: 0 0 70px;
}

.c-faq-wpr {
  max-width:950px;
}

.c-each-faq h3 {
  font-size: 26px;
  font-weight: 400;
  color: #0350bd;
  line-height: 1.2;
  margin: 0;
  cursor: pointer;
  position: relative;
}

.c-each-faq h3::before {
  content: '- ';
  position: relative;
  color: #ff8f1e;
  display: inline;
}

.c-faq-content p {
  font-size: 20px;
  font-weight: 400;
  color: #404040;
  line-height: 1.6;
  margin: 0;
  padding: 5px 10px 10px 20px;
}

.c-each-faq {
  border-bottom: 1px solid #000;
}

.c-faq-title {
  padding: 20px 0 17px;
  position: relative;
}

.c-faq-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
}

.c-faq-title::before {
  content: '';
  position: absolute;
  top: 26px;
  right: 0;
  width: 17px;
  height: 18px;
  background: url('../src/images/ang-arrow.png') no-repeat center;
  background-size: cover;
}

.c-faq-img {
  position: absolute;
  top: -62px;
  right: 0;
  max-width: 400px;
}

.c-faq-img img {
  border-radius: 5px;
}

.c-each-faq.open .c-faq-content {
  max-height: 300px;
}

/* inner page css */

.c-primary-page-sec{
  padding:70px 0 160px;
}

.c-page-title{
  font-size:48px;
  text-align:center;
  font-weight:700;
  color:#000000;
  line-height:1em;
  margin:0 0 65px;
}

.c-page-title span{
  color:#0250bd;
}

.c-primary-page-sec p{
  color:#3f3f3f;
  font-size:20px;
  font-weight:400;
  line-height:1.6em;
  margin:0 0 30px;
}

.c-primary-page-sec h2{
  color:#000000;
  font-size: 30px;
  font-weight:700;
  margin:0 0 40px;
}

.c-primary-page-sec h3{
  color:#0250bd;
  font-size:26px;
  font-weight:600;
  margin:0 0 16px;
}

.c-primary-page-sec h3 i{
  color: #ff8f1e;
  font-size: 18px;
  vertical-align: middle;
  margin-right: 13px;
}

.c-submit-btn.c-submit-btn{
  min-width:240px;
  background-color:#ff8f1e;
  color:#ffffff;
  font-size:22px;
  font-weight:700;
  border-radius:35px;
  padding: 13px 10px;
}

.c-submit-btn.c-submit-btn:hover{
  background:#0350bd;
  color:#FFF !important;
}

.c-small-btn.c-small-btn {
  min-width:125px;
  background-color:#ff8f1e;
  color:#ffffff;
  font-size:15px;
  font-weight:700;
  border-radius:35px;
  padding: 13px 10px;
  margin-top: 20px;
}

.c-small-btn.c-small-btn:hover{
  background:#0350bd;
  color:#FFF !important;
}

.c-modal-btn.c-modal-btn{
  margin-top: 15px;
  min-width:110px;
  background-color:#ff8f1e;
  color:#ffffff;
  font-size:15px;
  font-weight:700;
  border-radius:35px;
  padding: 13px 10px;
}

.c-modal-btn.c-modal-btn:hover{
  background:#0350bd;
  color:#FFF !important;
}

@media(max-width:1440px){
  body{
    zoom:85%;
  }
}
@media(max-width:1199.98px){
  .c-faq-img{
    position: static;
    top:0;
    margin:60px auto 40px;
  }
  .c-faq-sec{
    padding:40px 0;
  }
  .c-faq-sec h2 {
    font-size: 35px;
    margin: 0 0 25px;
    text-align: center;
  }
  .c-each-faq h3 {
    font-size: 22px;
    padding-right: 20px;
  }
  .c-faq-content p {
    font-size: 16px;
  }
  .c-site-info h4 {
    font-size: 20px;
  }
  .c-site-info a {
    font-size: 16px;
  }
  .c-page-title {
    font-size: 30px;
    margin: 0 0 35px;
  }
  .c-primary-page-sec p {
    font-size: 16px;
  }
  .c-primary-page-sec h2 {
    font-size: 24px;
    margin: 0 0 20px;
  }
  .c-primary-page-sec h3 {
    font-size: 20px;
  }
  .c-primary-page-sec h3 i {
    font-size: 13px;
  }
  .c-primary-page-sec {
    padding: 40px 0;
  }
}

@media(max-width:991.98px){
  .c-why-choose-us-sec,
  .c-work-sec{
    padding: 40px 0 !important;
  }
}

@media(max-width:767.98px){
  .c-main-banner-inner h1 {
    margin-bottom: 25px;
  }

  .o-footer {
    padding: 40px 0;
    text-align:center;
  }

  .c-site-info h4,
  .c-site-info ul{
    justify-content: center;
  }
}

.logo-footer {
  width: 250px;
}

.red-text {
  color: red;
}
