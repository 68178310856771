.fade-review-container {
    width: 90%;
    margin: 0 auto;
    text-align: center;
    position: relative;
    height: 300px; /* Adjust based on your content size */
    padding: 20px;
    box-sizing: border-box;
    border-radius: 10px;
    margin-bottom: 5%;
}

.review {
    position: absolute;
    width: 100%;
    top: 5px;
    left: 0;
    opacity: 0;
    transition: opacity 1s ease-in-out;
    padding: 20px;
    box-sizing: border-box;
}

.fade-in-out {
    opacity: 1;
}

.five-star-image {
    width: 200px; /* Adjust size as needed */
    margin-top: -70px;
}

.review p {
    margin: 20px 0;
    font-size: 16px;
    font-size: 1.5rem;
    color: #333;
    font-family: 'Arial', sans-serif;
    font-style: italic;
}

.review-author {
    font-size: 1rem;
    color: #555;
    font-weight: bold;
    font-family: 'Arial', sans-serif;
}
