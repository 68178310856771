.rc-loader-container {
  margin-top: 30px;
  text-align: center;
}

.rc-submit-btn-cont {
    text-align: center;
}

.rc-img-container {
  text-align: center;
  margin-bottom: 40px;
}

.callback-img {
  height: 110%;
  opacity: 0.75;
  border-radius: 5px;
}

@media (max-width:991.5px) {
  .rc-centred-info {
    text-align: center;
  }
}
