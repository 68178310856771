.error-page {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 10vh;
  height: 100vh;
  background-color: white;
  color: #ff8f1e;
}

.content {
  text-align: center;
}

.error-code {
  font-size: 5em;
  margin-bottom: 0.5em;
}

.error-text {
  font-size: 2em;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
