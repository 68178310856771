.cookie-banner {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: rgba(40, 40, 40, 0.8);
  color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 1000;
  font-size: 1.2rem; /* Large font size for readability */
}

.cookie-banner p {
  margin: 0 0 10px 0; /* Add some space below the text */
  flex-grow: 0;
}

.cookie-banner button {
  background-color: rgba(255, 143, 30, 0.9);
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1.2rem;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.cookie-banner button:hover {
  background-color: rgba(255, 160, 30, 0.9);
}

.cookie-banner a, .cookie-banner a:hover {
  color: white;
  text-decoration: underline;
}

@media (max-width: 768px) {
  .cookie-banner {
    font-size: 1.2rem; /* Adjust font size for smaller screens */
  }

  .cookie-banner button {
    width: 25%;
    padding: 10px;
    font-size: 1.2rem;
  }
}
