.faq-item {

}

.faq-question {
  cursor: pointer;
  color: var(--primary-color);
}

.faq-answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 1s ease-in-out;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 5%;
  text-align: left;
}

.faq-item.open .faq-answer {
  max-height: 200px;
}
