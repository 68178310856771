.c-main-header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding-top:40px;
}
.c-main-header.fixed,
.c-main-header.inner-header.fixed{
  position:sticky !important;
  background-color:#0250bd;
  padding:20px 0;
  z-index: 99;
}
.c-main-header.inner-header{
  position: static;
  background:#0351bd;
  padding:25px 0;
}
.c-nav-right {
  width: calc(100% - 229px);

}

.c-nav-top {
  margin-left: auto;
}

.c-nav-right {
  display: flex;
  align-items: center;
}

.navbar-nav {
  list-style: none;
  padding: 0;
  margin: 0;
}

.navbar-nav li {
  margin: 0 30px;
}

.navbar-nav li a {
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  text-transform: uppercase;
  line-height: 1;
  padding: 12px 0;
  position: relative;
}

.navbar-nav li a::after {
  content: '';
  position: absolute;
  width: 0px;
  height: 3px;
  background-color: #5de3f3;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 0;
  transition: all 0.3s ease-in-out;
}

.navbar-nav li a:hover::after,
.navbar-nav li a.active::after{
  width: 20px;
  transition: all 0.3s ease-in-out;
}
.offcanvas{
  transition:unset !important;
}
.c-header-phone {
  margin-left: 12px;
}

.c-header-phone a i {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background-color: #fff;
  text-align: center;
  line-height: 55px;
  color: #f99027;
  font-size: 20px;
}

.c-header-phone a span {
  font-size: 24px;
  font-weight: 600;
  color: #fff;
  padding-left: 20px;
}

/* .c-main-header .container {
  max-width: 90%;
} */

.c-manu-btn,
.c-btn-close {
  background-color: transparent;
  border: 0;
  outline: none;
  width: 42px;
  height: 42px;
  padding: 0;
}

.c-manu-btn img,
.c-btn-close img {
  width: 100%;
}

.c-logo-wpr .navbar-brand {
  margin-right: 0;
  padding: 0;
  width: 229px;
  display: block;
}

.sign-out-button {
  position: absolute;
  top: 10px;
  right: 25px;
  color: white;
  font-size: 1.2rem;
}

.sign-out-button:hover {
  cursor: pointer;
  color: orange;
}

@media (min-width:992px) {

  .c-manu-btn,
  .c-btn-close {
      display: none;
  }
}

@media (min-width:1720px) {
  .c-main-header .container {
      max-width: 1724px;
  }
}

@media (max-width:1399px) {
  .c-main-header {
      padding-top: 45px;
  }
}

@media (max-width:1280px) {
  .navbar-nav li {
      margin: 0 20px;
  }

  .navbar-nav li a {
      font-size: 16px;
  }

  .c-header-phone a span {
      font-size: 20px;
      padding-left: 14px;
  }

  .c-header-phone a i {
      width: 40px;
      height: 40px;
      line-height: 40px;
      font-size: 17px;
  }
}

@media (max-width:991px) {
  .c-logo-wpr .navbar-brand {
      width: 200px;
  }

  .c-nav-right {
      width: calc(100% - 200px);
  }

  .c-main-header .container {
      max-width: 95%;
  }

  .navbar-nav li {
      margin: 0;
      width: 100%;
  }

  .navbar-nav li a {
    font-size: 26px;
    color: #FFF;
    display: block;
    padding: 18px 15px;
  }

  .navbar-nav li a::after {
      margin:0;
      left: 17px;
  }

  .navbar-nav li a:hover,
  .navbar-nav li.current-menu-item a {
      background-color: #5de3f3;
      color: #0451bd;
  }

  .offcanvas-body.c-nav-wpr-body {
      padding-top: 80px;
      position: relative;
  }

  .offcanvas-body.c-nav-wpr-body .c-btn-close {
      position: absolute;
      top: 15px;
      left: 20px;
  }

  .c-main-header {
      padding-top: 25px;
  }

  .sign-out-button {
    top: 5px;
    right: 30px;
    font-size: 1rem;
  }

}

@media (max-width:575px) {
  .c-logo-wpr .navbar-brand {
      width: 160px;
  }

  .c-nav-right {
      width: calc(100% - 160px);
  }

  .c-header-phone a span {
      font-size: 16px;
      padding-left: 10px;
  }

  .c-header-phone a i {
      width: 32px;
      height: 32px;
      line-height: 34px;
      font-size: 15px;
  }
}

@media (max-width:479px) {
  .c-header-phone a span {
      display: none;
  }
}
