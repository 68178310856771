.admin-quotes {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  text-align: center;
  margin-top: 2%;
  width: 100%;
}

.admin-quotes .table-container {
  margin: 60px 0;
  max-width: 95%;
  overflow-x: auto;
}

.admin-quotes table {
  border-collapse: collapse;
  margin: 0 auto;
  max-width: 100%;
  box-sizing: border-box;
}

.admin-quotes th, .admin-quotes td {
  border: 1px solid #ddd;
  padding: 8px;
  box-sizing: border-box;
}

.admin-quotes tr:nth-child(even) {
  background-color: #f2f2f2;
}

.admin-quotes th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #f4f4f4;
  color: black;
  cursor: pointer;
}

.admin-quotes .email-recipients {
  margin-top: 10px;
  font-size: 0.7rem;
}

.admin-quotes .email-recipients label input {
  margin-right: 2px;
  margin-top: 5px;
}

.admin-quotes .larger-checkbox {
  transform: scale(1.6);
}

.admin-quotes .add-discount-but {
  background: none;
  border: none;
  outline: none;
  color: green;
  font-size: 30px;
  font-weight: bold;
  cursor: pointer;
  display: inline-block;
}

.admin-quotes .edit-icon, .send-icon {
  cursor: pointer;
  padding-bottom: 4px;
  height: 16px;
  color: orange;
}

.admin-quotes .send-icon {
  cursor: pointer;
  height: 20px;
  color: green;
}

.admin-quotes .red {
  color: red;
}
