.offline-wrapper .offline-message {
  position: fixed;
  top: 5%;
  left: 50%;
  transform: translate(-50%, -50%); /* Adjust for exact centering */
  max-width: 80%;
  padding: 0.75rem;
  background-color: red;
  color: white;
  text-align: center;
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-family: 'Lato', sans-serif;
  font-size: 1rem;
  z-index: 1001;
}

.offline-wrapper .offline-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.offline-wrapper .offline-interactive-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 999;
  pointer-events: none;
}
