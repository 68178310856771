.admin-settings {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh; /* Use 100% of the viewport height */
  text-align: center;
  margin-top: 2%;
}

.admin-settings .setting {
  margin-top: 50px;
}

.admin-settings .setting-title {
  margin-bottom: 30px;
}

.admin-settings .product-upload-text-area {
  margin-bottom: 10px;
  padding: 10px;
  height: 200px;
  width: 100%;
}

.admin-settings .product-view-text-area {
  font-family: inherit;
  font-size: inherit;
  padding: 3% 15%;
  white-space: pre-wrap; /* This ensures the text wraps within the element */
  word-wrap: break-word; /* This helps to break long words to fit within the container */
}

.settings-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2%;
}

.settings-button {
  margin-bottom: 10px;
}

.admin-settings .form-group {
  margin-bottom: 15px;
}

.admin-settings .form-group input, .select-box {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

.admin-settings button {
  width: 100%;
  padding: 10px;
  background-color: grey;
  color: white;
  border: none;
  cursor: pointer;
  margin: 10px 0;
}

.admin-settings button:hover {
  opacity: 0.9;
}

.admin-settings .dropdown-label {
  margin-right: 20px;
}

.admin-settings .larger-checkbox {
  transform: scale(1.6);
  margin: 30px 20px;
}

.admin-settings .other-settings-larger-checkbox {
  transform: scale(1.6);
  margin: 15px 20px;
}

.admin-settings .discount-codes-list {
  list-style-type: none; /* Remove bullet points */
  padding: 0; /* Remove padding for a cleaner look */
  margin: 0; /* Remove margin for a cleaner look */
}

.admin-settings .discount-code-item {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr auto; /* Define columns for name, checkbox+label, and button */
  align-items: center;
  gap: 10px;
  padding: 10px 0; /* Add some padding for better spacing */
  border-bottom: 1px solid #ccc; /* Add a bottom border for separation */
}

.admin-settings .discount-code-name {
  font-weight: bold;
  font-family: 'Arial', sans-serif;
}

.admin-settings .discount-code-checkbox {
  margin-right: 5px; /* Add margin between checkbox and label */
}

.admin-settings .discount-code-label {
  font-family: 'Arial', sans-serif;
}

.admin-settings .x-button {
  background: none;
  border: none;
  outline: none;
  color: red;
  font-size: 20px;
  font-weight: bold;
  padding: 5px;
  cursor: pointer;
  justify-self: end; /* Align the button to the end */
}

.admin-settings .add-discount-code-button {
  margin-top: 50px;
}

.admin-settings .other-settings {
  margin-bottom: 5px;
}

.admin-settings .other-setting {
  margin-bottom: 1px;
}
