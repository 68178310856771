.instant-quote-tool {
  width: 95%;
  max-width: 600px;
  margin: 0 auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 20px;
  padding-bottom: 5px;
  background-color: #ffffff;
  margin-bottom: 20%;
}

.instant-quote-tool .question-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.instant-quote-tool .question-text {
  font-size: 1.1rem;
  margin: 0 1.5em;
  margin-bottom: 1em;
  font-weight: bold;
}

.instant-quote-tool .input-container {
  margin-top: 20px;
}

.instant-quote-tool label {
  font-size: 0.95rem;
}

.instant-quote-tool .notes-container {
  display: flex;
  align-self: flex-start;
  justify-self: flex-start;
  padding: 2%;
  margin-top: 10px;

}

.instant-quote-tool .notes-button {
  font-weight: bolder;
  font-size: 2rem;
  color: silver;
  margin-left: 10px;
  margin-top: -1.4em;
  animation: flash 1s ease-in-out 1;
}

@keyframes flash {
  0%, 100% { opacity: 1; }
  50% { opacity: 0.5; }
}

.instant-quote-tool .notes-button:hover {
  cursor: pointer;
}

.instant-quote-tool .question-container .radio-label {
  display: flex;
  align-items: left;
  justify-content: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.instant-quote-tool label {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Hide the default radio button */
.instant-quote-tool .question-container input[type="radio"] {
  display: none;
}

/* Create a custom radio button */
.instant-quote-tool .question-container .custom-radio {
  display: inline-block;
  width: 20px;  /* Adjust as needed */
  height: 20px;  /* Adjust as needed */
  border-radius: 50%;
  border: 2px solid #F99027;
  margin-right: 10px;
}

/* Style the custom radio button when the associated input is checked */
.instant-quote-tool .question-container input[type="radio"]:checked + .custom-radio {
  background: radial-gradient(circle, #0351BD 10%, #0351BD 50%, transparent 60%, transparent 100%);
}

.instant-quote-tool button {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.instant-quote-tool .question-note {
  font-size: 0.8rem;
  margin-bottom: 0.75em;
  text-align: justify;
  margin-top: -10px;
}

.instant-quote-tool .select-container {
  width: 50%;
  margin: 20px 0;
  text-align: center;
  text-align-last: center;
}

.instant-quote-tool .styled-select {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 1rem;
  color: #333;
  appearance: none; /* this will remove the default arrow of select */
  background: url('data:image/svg+xml;utf8,<svg fill="orange" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>') no-repeat right 10px center;
  background-size: 18px;
  background-color: white;
}

.instant-quote-tool .radiator-container label {
  display: flex;
  justify-content: center;
  flex-grow: 1;
}

.instant-quote-tool .radiator-input {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: 2px solid #ccc;
  padding: 2px;
  border-radius: 5px;
  background-color: white;
  width: 25%;
  color: #333;
  font-size: 1rem;
  outline: none;
  margin: auto;
  text-align: center;
  min-width: 60px;
  margin-top: 5px;
  margin-bottom: 20px;
}

.instant-quote-tool .radiator-input:focus {
  border: 2px solid var(--input-outline-color);
}

.instant-quote-tool .radiator-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.instant-quote-tool .radiator-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.instant-quote-tool .personal-details-form {
  margin-top: 30px;
}

.instant-quote-tool .personal-details-form .personal-input {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: 2px solid #ccc;
  border-radius: 5px;
  margin-top: 2px;
  margin-bottom: 15px;
  background-color: white;
  padding: 4px;
  width: 100%;
  color: #333;
  font-size: 1rem;
  outline: none;
  text-align: center;
}

.instant-quote-tool .personal-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.instant-quote-tool .personal-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.instant-quote-tool .personal-details-form .personal-input:focus {
  border-color: var(--input-outline-color);
}

.instant-quote-tool .consent-checkbox {
  padding-top: 25px;
}

.instant-quote-tool .consent-checkbox-text {
  color: black;
  font-size: 0.75rem;
  margin-left: 3px;
}

.instant-quote-tool .back-arrow {
  color: silver;
  display: flex;
  align-items: left;
  justify-content: left;
  font-size: 2rem;
  margin-left: -10px;
}

.instant-quote-tool .back-arrow:hover {
  opacity: 0.5;
  cursor: pointer;
}

.instant-quote-tool .step0-text {
  margin-bottom: 15px;
  padding: 0 50px;
  text-align: center;
  font-size: 1.3rem;
}

.instant-quote-tool .step0-text-small {
  margin-bottom: 15px;
  padding: 0 50px;
  text-align: center;
  font-size: 0.9rem;
}

.instant-quote-tool .step0-highlighted-text {
  color: #F99027;
  font-weight: bold;
}

.instant-quote-tool .step0-link {
  color: var(--font-color);
  font-weight: bold;
}

.instant-quote-tool .label-container {
  margin-bottom: 10px;
}

.instant-quote-tool .inline-radio-label-container {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 20px;
  margin-bottom: 10px;
}

.instant-quote-tool .inline-radio-label-container .inline-radio-label {
  font-size: 0.8rem;
}

.instant-quote-tool .inline-radio-label {
  display: flex;
  align-items: center;
  gap: 1px;
}

.instant-quote-tool .inline-radio-title {
  margin-bottom: 5px;
  font-size: 0.75rem;
  font-weight: bold;
}

.instant-quote-tool .description-container {
  display: flex;
  flex-direction: row; /* Default value, but added for clarity */
  flex-wrap: wrap; /* Makes the layout responsive */
  align-items: center;
  margin: 30px 0;
}

.instant-quote-tool .description {
  flex: 0 0 60%; /* Takes up 40% of the parent's width */
   max-width: 60%; /* Important for responsiveness */
   text-align: justify;
   padding-right: 30px;
}

.instant-quote-tool .image-div {
  flex: 1; /* Takes up the remaining space */
}

@media (max-width: 768px) {
    .instant-quote-tool .description, .instant-quote-tool .image-div {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .instant-quote-tool .description {
      margin-top: 20px;
    }

    .instant-quote-tool .description-centered {
      text-align: center;
    }
}

.instant-quote-tool .description .description-paragraph {
  margin-bottom: 10px;
  font-size: 0.85rem;
  font-style: italic;
}

.instant-quote-tool .description-container .boiler-imgs {
  width: 150px;
  height: 250px;
}

.instant-quote-tool .description-container .programmer-imgs {
  width: 100px;
  height: 125px;
}

.instant-quote-tool .description-container .description-extra-padding:first-of-type {
  padding-top: 20px;
}

.instant-quote-tool .description-container .description-extra-padding-programmer {
  padding-top: 20px;
}

.instant-quote-tool .extra-padding-manuf {
  padding-top: 20px;
}

.instant-quote .button-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 20px 0;
}

.instant-quote-tool .button-container .number-button {
  flex: 1;
  min-width: 100px;
  max-width: 100px;
  min-height: 70px;
  margin: 10px;
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  background-color: white;
  color: var(--font-color-light);
  padding: 15px 10px;
  cursor: pointer;
  font-family: var(--font-family);
  transition: background-color 0.3s ease;
  border: 3px solid transparent;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(128,128,128, 0.6);
  font-size: 14px;
  font-weight: bold;
  display: block;
  border: 3px solid #D3D3D3;
}

.instant-quote-tool .button-container .number-button:hover {
  box-shadow: 0px 6px 8px grey;
}

.instant-quote-tool .button-container .number-button.selected {
  background-color: #74aefc;
  transform: scale(0.95);
}

.instant-quote-tool .button-container .number-button-img {
  display: block;
  margin: 5px auto 0;
  color: red;
}

.instant-quote-tool .rotated {
  transform: scaleX(-1) rotate(-90deg);
}

.instant-quote-tool .contact-form {
  margin-top: 40px;
}
