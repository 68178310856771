.accept-quote {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
  box-sizing: border-box;
  background-color: #f5f5f5;
}

.accept-quote .accept-quote-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
  border: 2px solid #322d31;
  border-radius: 10px;
  background-color: white;
  max-width: 600px;
  width: 100%;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  text-align: center;
}

.accept-quote .accept-quote-title {
  font-size: 24px;
  color: #322d31;
  margin-bottom: 20px;
}

.accept-quote .accept-quote-message {
  margin-bottom: 30px;
}

@media (max-width: 600px) {
  .accept-quote .accept-quote-card {
    padding: 20px;
  }

  .accept-quote .accept-quote-title {
    font-size: 20px;
  }

  .accept-quote .accept-quote-message {
    font-size: 16px;
  }
}
