.partners-carousel {
  margin-top: 20px;
  margin-bottom: 0;
}

.image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.image-inner-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.carousel-image {
  max-width: 100px;
  max-height: 80px;
  filter: grayscale(100%) brightness(200%);
  transition: transform 0.5s;
}

/* The main (center) image */
.slick-slide.slick-center .carousel-image {
  opacity: 1;
}

/* The side images */
.slick-slide .carousel-image {
  opacity: 0.5;
}
