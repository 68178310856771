.admin-jobs {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  text-align: center;
  margin-top: 2%;
  width: 100%;
}

.admin-jobs .table-container {
  margin: 60px 0;
  max-width: 95%;
  overflow-x: auto;
}

.admin-jobs table {
  border-collapse: collapse;
  margin: 0 auto;
  max-width: 100%;
  box-sizing: border-box;
}

.admin-jobs th, .admin-jobs td {
  border: 1px solid #ddd;
  padding: 8px;
  box-sizing: border-box;
}

.admin-jobs tr:nth-child(even) {
  background-color: #f2f2f2;
}

.admin-jobs th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #f4f4f4;
  color: black;
  cursor: pointer;
}

.admin-jobs .email-recipients {
  margin-top: 10px;
  font-size: 0.7rem;
}

.admin-jobs .email-recipients label input {
  margin-right: 2px;
  margin-top: 5px;
}

.admin-jobs .disabled-row {
  color: grey;
  opacity: 0.6;
}

.admin-jobs .larger-checkbox {
  transform: scale(1.6);
}

.admin-jobs .x-button {
  background: none;
  border: none;
  outline: none;
  color: red;
  font-size: 20px;
  font-weight: bold;
  padding: 5px;
  cursor: pointer;
  display: inline-block;
  margin-bottom: 10px;
}

.admin-jobs .small-x-button {
  font-size: 15px;
}

.admin-jobs .x-button-disabled {
  cursor: default;
}

.admin-jobs .additional-costs-but {
  background: none;
  border: none;
  outline: none;
  color: green;
  font-size: 30px;
  font-weight: bold;
  cursor: pointer;
  display: inline-block;
}

.admin-jobs .additional-costs-but-disabled {
  cursor: default;
}

.admin-jobs .additional-cost-item {
  text-transform: capitalize;
  font-size: 0.8rem;
  margin: 5px 0;
  border-top: 1px solid grey;
  padding-top: 15px;
}

.admin-jobs .additional-cost-amount {
}

.admin-jobs .send-emails-button {
  font-size: 0.75rem;
  border-radius: 10%;
  padding: 5px;
  background: green;
  color: white;
}

.admin-jobs .tabs {
  margin-top: 30px;
}

.admin-jobs .tab {
  font-size: 1.3rem;
  cursor: pointer;
  margin-right: 40px; /* Space between tabs */
  font-style: italic;
}

.admin-jobs .activeTab {
  border-bottom: 5px solid grey;
}

.admin-jobs .red {
  color: red;
}
