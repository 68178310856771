.initial-visit {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
  box-sizing: border-box;
  background-color: #f5f5f5;
}

.initial-visit .container {
  text-align: center;
  width: 90%;   width: 400px; max-width: 800px;   margin: auto;
}

.initial-visit .job-details {
  text-align: left;   display: flex;
  flex-direction: column;
  align-items: center;   margin-top: 40px;
  width: 100%;
  margin-bottom: 40px;
}

.initial-visit .initial-visit-btn {
  margin-top: 30px;
  margin-bottom: 15px;
}

.initial-visit .loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative;
  top: -150px;
  left: 0;
  right: 0;
  bottom: 0;
}

.initial-visit .load-cont {
  text-align: center;
}

.initial-visit .input-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 50px;
}

.initial-visit .input-group label {
  display: block;
  margin-bottom: 20px;
  font-weight: bold;
  color: #333;
  width: 100%;
  text-align: center;
}

.initial-visit .radio-group {
  display: flex;
  justify-content: center;
  gap: 5px; /* Space between radio buttons */
}

.initial-visit .radio-group label {
  margin: 0;
  font-weight: normal;
  font-size: 0.95rem;
}

.initial-visit .radio-group input[type="radio"] {
  margin-right: 25px; /* Space between radio button and its label */
}

.initial-visit .input-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f9f9f9;
}

.initial-visit .input-group .disabled-input {
  color: #999;
  cursor: not-allowed;
}

.initial-visit .large-textarea {
  width: 100%;
  height: 150px;
}

.initial-visit .center-button {
  text-align: center;
}

.initial-visit .confirm-details-group {
  border-top: 1px solid lightGrey;
  border-bottom: 1px solid lightGrey;
  padding: 30px 0;
}

.initial-visit .confirm-details-group-input {
  margin-bottom: 40px;
}
