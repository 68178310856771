.admin-engineers {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  text-align: center;
  margin-top: 2%;
  width: 100%;
}

.admin-engineers .table-container {
  margin: 60px 0;
  max-width: 95%;
  overflow-x: auto;
}

.admin-engineers table {
  border-collapse: collapse;
  margin: 0 auto;
  max-width: 100%;
  box-sizing: border-box;
}

.admin-engineers th, .admin-engineers td {
  border: 1px solid #ddd;
  padding: 8px;
  box-sizing: border-box;
}

.admin-engineers tr:nth-child(even) {
  background-color: #f2f2f2;
}

.admin-engineers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #f4f4f4;
  color: black;
  cursor: pointer;
}

.admin-engineers .x-button {
  background: none;
  border: none;
  outline: none;
  color: red;
  font-size: 20px;
  font-weight: bold;
  padding: 5px;
  cursor: pointer;
  display: inline-block;
  margin-bottom: 10px;
}
