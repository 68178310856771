.contact-img {
  height: 85.5%;
  object-fit: cover;
  opacity: 0.75;
  border-radius: 5px;
}

.c-contact-info {
  padding: 0;
  margin: 0 0 40px;
  list-style: none;
}

.c-contact-info > li {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  padding-left: 78px;
  margin-right: 80px;
  margin-bottom: 30px;
}

.c-contact-info > li i {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.c-contact-info > li > a {
  color: #0250bd;
  font-size: 24px;
  font-weight: 500;
}

.contact-form .form-control {
  height: 58px;
  background: #eefbfc;
  border: #76c1c8 1px solid;
  padding: 0 25px;
  color: #3f3f3f;
  font-size: 20px;
  font-weight: 400;
  box-shadow: #b9f1f1 3px 5px 16px;
  margin: 0 0 40px;
}

.contact-form textarea.form-control {
  height: 190px;
  padding: 20px 25px;
}

.loader-container {
  margin-top: 30px;
  text-align: center;
}

.c-submit-btn-container {
  text-align: center;
}

/* Media Queries */
@media (max-width: 1199.98px) {
  .c-contact-info > li {
    margin-right: 0;
    margin-bottom: 40px;
  }
}

@media (max-width: 991.98px) {
  .c-contact-info > li {
    margin-right: 15px;
  }
}

@media (max-width: 767.98px) {
  .c-contact-info > li {
    margin-right: 0;
    margin-bottom: 45px;
  }
}
