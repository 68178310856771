.instant-quote {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  text-align: center;
  margin: 40px 0;
}

.instant-quote .initial-title {
  margin-bottom: 5%;
}

.instant-quote .main-title {
  margin-bottom: 4%;
}
