.admn .admin-message {
  margin-top: 20px;
}

.admn .admin-form .form-control {
  height: 58px;
  background: #eefbfc;
  border: #76c1c8 1px solid;
  padding: 0 25px;
  color: #3f3f3f;
  font-size: 20px;
  font-weight: 400;
  box-shadow: #b9f1f1 3px 5px 16px;
  margin: 0 0 40px;
}
