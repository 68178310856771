.services-cont {
  text-align: justify;
}

.services-cont h2 {
  margin: 4% 0 80px 0;
  text-align: center;
}

.services-image {
  width: 98%;
  height: 93%;
  border-radius: 5px;
}
